<template>
    <div class="qs-header q-box">
        <div class="logo"></div>
        <div class="body">
            <slot></slot>
        </div>
        <slot name="right"></slot>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.qs-header{
    display:flex;align-items: center;font-size:24px;height:44px;
    .body{flex:1;}
}

</style>
