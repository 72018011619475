<template>
    <div class="product-body main product-show">

        <!-- <swiper class="banner" :options="swiperOption">
            <swiper-slide v-for="(item, index) in swipes" :key="index">
                <img :src="item.img"  style="max-width:100%"/>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>  -->
    <van-swipe :autoplay="3000" >
      <van-swipe-item v-for="(item, index) in swipes" :key="index" style="text-align:center;">
        <van-image fit="cover"  :src="item.img" />
      </van-swipe-item>
    </van-swipe>           
        <QsNavbar class="float-navbar" :onLeftClick="onLeftClick">
            <template #right>
                <router-link to="/"><van-icon name="wap-home-o" size="20" style="margin-right:14px"/></router-link>
                <van-icon name="like-o" :color="item.faved?'$blue_color !important':''" size="20" @click="onFav"/>
            </template>
        </QsNavbar>         
        <h5 class="title">{{item.name}}</h5>            
        <div class="q-box q-box-b" v-if="item.desc">
            <div class="header left">产品详情</div>
            <div class="desc" v-html="item.desc"></div>
        </div>
        <div class="q-box q-box-b" v-if="item.advantage">
            <div class="header left">特点和优点</div>
            <div class="desc" v-html="item.advantage"></div>
        </div>           
        <div class="q-box q-box-b" v-if="item.features">
            <div class="header left">产品特点和亮点</div>
            <div class="desc" v-html="item.features"></div>
        </div> 
        <div class="q-box q-box-b" v-if="item.industry">
            <div class="header left">应用行业</div>
            <div class="desc" v-html="item.industry"></div>
        </div>    
        <div class="q-box q-box-b" v-if="item.video!=''">
            <div class="header left">相关视频</div>
            <d-player ref="player" :options="dplayerOptions"></d-player>
        </div> 
        <div class="q-box q-box-b other-box" v-if="others.length>0">
            <div class="header left" style="padding-bottom:0;">其它产品</div>
            <QsProductItem :item="item" v-for="item in others" :key="item.id"></QsProductItem>
        </div>   
        <div class="q-box q-box-b" v-if="docs.length>0 && false">
            <div class="header left">相关文档</div>
            <ul class="doc-list">
                <li v-for="doc in docs" :key="doc.id">
                    <a :href="doc.path" target="_blank">
                        <van-icon name="description" />
                        <span>{{doc.name}}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="d-flex" style="background:#fff;padding: 20px;justify-content: space-around;" v-if="item.doc_link">
            <a :href="item.doc_link" class="btn-link">查看更多产品详情 <van-icon name="arrow" /></a>
            <a href="http://jsform3.com/web/formview/5d9fff6f75a03c51474e131f" class="btn-link">申请样品 <van-icon name="arrow" /></a>
        </div>            
        <div class="q-box" style="height:80px;"></div>                                 
    </div>
</template>

<script>

import Vue from 'vue'

import VueDPlayer from 'vue-dplayer'
import 'vue-dplayer/dist/vue-dplayer.css'

import QsHeader from '@components/qs-header'
import QsProductItem from '@components/qs-product-item'

export default {
    components: { QsHeader,QsProductItem,'d-player': VueDPlayer },
    data(){
        return{            
            id:0,
            item:{
                name:'',
                desc:'',
                imgs:'',
                video:'',
                faved:false,
            },
            docs:[],
            others:[],
            swiperOption: {
                // slidesPerView: 'auto',
                centeredSlides: true,
                // spaceBetween: 10,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
            },
            dplayerOptions:{
                video: {
                    // url:'http://47.105.206.28/videos/wangkun.mp4'
                }
            },
        }
    },
    created(){
        this.id = this.$route.query.id || 0;
    },
    mounted(){
        this.onLoad();
    },
    methods:{
        onLoad(){
            this.$ajax.get("client/product/show?id="+this.id).then((res)=>{
                this.item = res.data.item;
                console.log(this.item);
                this.docs = res.data.docs;
                this.others = res.data.others;
                if(this.item.video){
                    this.dplayerOptions.video.url = this.item.video;
                    // this.$nextTick(()=>{
                    //     const player = this.$refs.player.dp;
                    //     player.switchVideo({
                    //         url:this.item.video,
                    //     })
                    // });
                }
            }).catch(e=>{
                console.log(e);
            })
        },
        onFav(){
            this.$ajax.get("client/user/productFav?pid="+this.id).then(res=>{
                this.$notify({ type: 'success', message: res.data});   
                this.item.faved = !this.item.faved;
            })
        },
        onLeftClick(){
            this.$router.push({path:'/product/list',query:{cid:this.item.cate_id}})
        }        
    },
    computed:{
        swipes(){
            let arr = this.item.imgs.split("|");
            let items=[];
            arr.forEach(p=>{
                if(p){
                    items.push({img:p})
                }
            })
            return items;
        }
    },
    watch:{
        $route (to, from,savedPosition) {
            // console.log(this.$refs,savedPosition);
            // let app = this.$refs['app'];
            // app.scrollTo(0,0);
            this.id = to.query.id;
            this.onLoad();
        }
    }
}
</script>

<style lang="scss" scoped>
.van-swipe{font-size:1rem;}
.title{padding:13px 16px;font-size: 18px;background:$blue_color;color:#fff;margin-bottom: 20px;}
.header{font-weight: $fw;color:$blue_color;}
.header.left::before{background-color:$blue_color;}
.desc{color: #828282;overflow: hidden;word-break: break-all;font-size: 14px;}
.desc img{max-width: 100%;}

.doc-list{font-size:14px;}
.doc-list li{display:flex;padding:8px;align-items: center;margin-bottom: 8px;background:rgba(249, 249, 249, 100);color: $blue_color;;}
.doc-list li span{flex:1;}
.doc-list li a{color:$blue_color;}

.float-navbar ::v-deep{
    position:absolute;top:0;width:100%;background:none;font-size: 14px;
    .van-nav-bar__right .van-icon{background:#fff;border-radius: 50%;padding:2px;}
}
::v-deep .float-navbar::after{border:none;}
.other-box:last-of-type .product-box ::v-deep .product-item {border-bottom:none;}
::v-deep .van-nav-bar__left .van-icon-arrow-left{font-size: 24px;font-weight: $fw;color:#000 !important;}
.btn-link{border-radius: 5px;background: $blue_color;color:#fff;font-size: 14px;padding: 8px 18px;min-width: 124px;display: inline-block;}
.btn-link .van-icon{background: #fff;border-radius: 50%;color:$blue_color;margin-left: 10px;padding: 3px;}
.btn-link .van-icon-arrow:before{font-size: 15px;font-weight: bold;}
</style>


