<template>
        <router-link :to="{path:'/product/show',query:{id:this.item.id}}" class="product-box">
            <van-card
                class="product-item"
                desc="描述信息"
                :title="item.name"
                :thumb="thumb"
            >
            <template #desc>
                <div class="desc">
                    {{item.desc}}
                </div>
            </template>
                <!-- <template #footer>
                    <van-icon name="clock" size="16" />
                </template>           -->
            </van-card>
        </router-link>
</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            required:true,
            default: function () {
                return { id:0,name: '',imgs:'',desc:'' }
            }
        }
    },
    data(){
        return{
            
        }
    }, 
    methods:{
    },
    computed:{
        thumb(){
            let arr = this.item.imgs.split("|");
            return arr[0];
        }
    }   
}
</script>

<style lang="scss" scoped>
.product-item ::v-deep{
        position: relative;margin-bottom:15px;padding:15px 0;border-bottom:1px solid rgba(230, 230, 230, 100);background:#fff;
        .van-card__title{color:#000;font-weight: $fw;padding-bottom:10px;-webkit-line-clamp:1 ;word-break: break-all;white-space: nowrap;display: block;font-size: 14px;}
        .van-card__thumb{width:100px;height:80px;margin-right:15px;}
        .van-card__thumb img{max-width:100%;}
        .desc{
            -webkit-line-clamp: 3;overflow: hidden;word-break: break-all;-webkit-box-orient: vertical;text-overflow: ellipsis;display: -webkit-box;
            
        }
        .van-card__footer{
            position: absolute;right:16px;bottom:16px;
            .van-icon-clock{transform:rotate(222deg); }
        }
    }
</style>
